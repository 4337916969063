<template>
  <div class="header">
    <a-row class="nav">
      <a-col class="logo" :xs="0" :md="10">
        <img class="images" src="../assets/image/组 348.png" />
      </a-col>
      <a-col class="nav-list" :xs="24" :md="14">
        <nav>
          <li
            v-for="item in menuList"
            :class="[ item.id, item.url === path ? 'li-active' : '']"
            :key="item.id"
            @click="gourl(item)"
          >
            {{ item.name }}
          </li>
          <!-- <div class="solutionList">
              <div v-for="item in solutionList" :key="item.id">
                {{ item.name }}
              </div>
          </div> -->
        </nav>


      </a-col>

    </a-row>
    <Banner :imgSrc="bannerUrl"></Banner>
  </div>
</template>

<script>
import Banner from "./Banner";

export default {
  components: {
    Banner
  },
  data() {
    return {
      menuList: [
        {
          id: 0,
          name: "首页",
          url: "home",
          src: require("../assets/image/组 786.png")
        },
        {
          id: 1,
          name: "关于我们",
          url: "about",
          src: require("../assets/image/组 828.png")
        },
        // { id: 2, name: '产品中心', url: 'prodCenter', src: require('../assets/image/827.png') },
        {
          id: 3,
          name: "典型案例",
          url: "typicalCase",
          src: require("../assets/image/32656.png")
        },
        // { id: 4, name: '新闻中心', url: 'newsCenter', src: require('../assets/image/830.png') },
        { id: 4, name: "解决方案", url: "solution", src: require("../assets/image/830.png") },
        // { id: 5, name: '人才发展', url: 'talentDevelopment', src: require('../assets/image/829.png') },
        {
          id: 6,
          name: "招聘中心",
          url: "recruitmentCenter",
          src: require("../assets/image/831.png")
        }
      ],
      bannerUrl: "",
      path: "",
      // toHAndBanner:{
      //   bannerUrl:'',
      //   toH:'',
      //   thHTwo:'',
      // }
      solutionList: [
        {
          name: "酿酒行业控制系统解决方案",
          id: 1
        }, {
          name: "制药行业控制系统解决方案",
          id: 2
        }, {
          name: "香精香料、调味品行业控制系统解决方案",
          id: 3
        }, {
          name: "粮油行业控制系统解决方案",
          id: 4
        }, {
          name: "谷物饲料行业控制系统解决方案",
          id: 5
        }, {
          name: "环保行业控制系统解决方案",
          id: 6
        }, {
          name: "洁净生产BMS/EMS控制系统解决方案",
          id: 7
        }, {
          name: "FMCS厂务管理控制系统解决方案",
          id: 8
        }
      ],
    };
  },
  created() {
    this.gourl(this.menuList[0]);
  },
  mounted() {
  },
  methods: {
    gourl(item) {
      // this.toHAndBanner.bannerUrl=item.src;
      // this.toHAndBanner.toH=item.menuH;
      // this.toHAndBanner.thHTwo=item.menuHTwo;
      this.bannerUrl = item.src;
      this.$router.push({ name: item.url });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(nVal, Oval) {
        let arr = nVal.path.split("/");
        this.path = arr[2];
      }
    }
  }
};
</script>

<style lang="less">
.header {
  width: 100%;
  position: relative;

  .nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.39);

    .logo {
      height: 100%;
      text-align: center;
      line-height: 100px;

      .images {
        width: 198px;
        height: 48px;
      }
    }

    .nav-list nav {
      // font-size: 0.18rem;
      position: relative;
      height: 100%;
      width: 100%;
      text-align: center;
      line-height: 100px;
      display: flex;

      li {
        width: 14.3%;
        cursor: pointer;
        position: relative;
        transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        color: #ffffff;

        &:hover {
          font-weight: bold;
          background-color: #ff6e29;

          &::before {
            opacity: 1;
            height: 5px;
            transition: all 0.5s;
          }
        }

        &::before {
          content: "";
          width: 50%;
          height: 3px;
          position: absolute;
          background-color: #ffffff;
          top: 80%;
          left: 25%;
          opacity: 0;
        }
      }

      .solutionList {
        position: absolute;
        width: 600px;
        background-color: #fff;
        top: 100% + 10px;
        //left: 14.3% * 3;
        visibility: hidden;
        padding: 20px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          width: 240px;
          height: 30px;
        }
      }

      li:nth-child(4):hover ~ .solutionList {
        visibility: visible;
      }
    }
  }

  .banner {
    width: 100%;
  }

  .li-active {
    color: #ffffff;
    font-weight: bold;
    background-color: #ff6e29;
  }


}

@media all and (max-width: 700px) {
  .header {
    .nav {
      height: 260px;

      .nav-list nav {
        height: 260px;

        li {
          width: 400px;
          line-height: 260px;
          font-size: 50px;
        }
      }
    }
  }

  .banner {
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <a-row class="index">
    <!-- 头部 -->
    <Header> </Header>
    <router-view> </router-view>
    <Footer></Footer>
  </a-row>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="less">
.index {
  width: 100%;
}
</style>

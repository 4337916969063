<template>
  <div class="banner-context">
    <img class="banner" :src="imgSrc" />
    <!-- <div class="h">
      <h1 align="center" class="animate__animated animate__bounceInRight">{{dataObj.toH}}</h1>
      <h2 align="center" class="animate__animated animate__bounceInRight">{{dataObj.thHTwo}}</h2>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    // HandB: {
    //   type: Object,
    //   default() {
    //     return {}
    //   }
    // }
    imgSrc: {
      type: String,
    },
  },
  data() {
    return {
      dataObj: null,
    };
  },
  watch: {
    // HandB: {
    //   deep: true,
    //   handler(newV) {
    //     console.log(newV);
    //     // 将监听到的值的修改赋予到dataObj中
    //     this.dataObj = JSON.parse(JSON.stringify(newV))
    //   }
    // }
  },
};
</script>

<style lang="less">
.banner-context {
  // position: relative;
  // .h {
  //   width: 100%;
  //   position: absolute;
  //   top: -320px;
  //   h1 {
  //     color: #ffffff;
  //     font-size: 72px;
  //     font-family: Quantum;
  //     font-weight: 400;
  //   }
  //   h2 {
  //     color: #ffffff;
  //     font-size: 44px;
  //     font-family: Source Han Sans CN;
  //     font-weight: 500;
  //   }
  // }
}
</style>
